import { useRouter } from 'next/dist/client/router'
import { useCallback } from 'react'

export function useRefresh() {
	const router = useRouter()

	const refresh = useCallback(() => {
		const newUrl =
			router.asPath.indexOf('?') > -1
				? `${router.asPath}&r=${Math.random()}`
				: `${router.asPath}?r=${Math.random()}`

		router.replace(newUrl, router.asPath)
	}, [router])

	return refresh
}
