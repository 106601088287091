import clsx from 'clsx'
import React from 'react'
import s from './Header.module.sass'
import { Logo } from './Logo'

export function Header() {
	return (
		<div className={s.Container}>
			<div className={s.Header}>
				<div className={clsx(s.Cell, s.logo)}>
					<Logo />
				</div>
				<div>
					<span className={s.Title}>Groceries</span>
				</div>
			</div>
		</div>
	)
}
