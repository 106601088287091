import clsx from 'clsx'
import moment from 'moment'
import 'moment/locale/cs'
import { useRouter } from 'next/dist/client/router'
import NextImage from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'
import { createFetcher } from '../../packages/@necktip/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import { checkDueDate, dueDateOptions } from '../assets/helpers/dueDateOptions'
import { useRefresh } from '../assets/helpers/useRefresh'
import type { ShoppingListItemResult } from '../data/ShoppingItemFragment'
import SvgCheckmark from '../svg/icons/Checkmark'
import SvgCross from '../svg/icons/Cross'
import { SelectInput } from './SelectInput'
import s from './ShoppingListItem.module.sass'

const api = createFetcher<API>('/api/v1/[handler]')

export function ShoppingListItemSupplier(props: {
	item: ShoppingListItemResult
	history?: boolean
}) {
	const [submitCount, setSubmitCount] = useState(0)
	const refresh = useRefresh()

	const router = useRouter()

	const handlePurchased = async () => {
		const data = props.item
		try {
			await api('updateShoppingListItemPurchased', {
				id: data.id,
			})
			refresh()
		} catch (e) {
			console.log(e)
		}
	}

	const handleRemoved = async () => {
		const data = props.item
		try {
			await api('updateShoppingListItemRemoved', {
				id: data.id,
			})
			refresh()
		} catch (e) {
			console.log(e)
		}
	}

	const isDue = moment(props.item.dueDate).isBefore(moment())

	const [doubleCheckVisible, setDoubleCheckVisible] = useState(false)

	const doubleCheck = () => {
		setDoubleCheckVisible(true)
	}

	return (
		<>
			{!history &&
				(props.item.supplier ? (
					props.item.supplier.logo ? (
						<div
							className={clsx(props.item.supplier.contact && s.LogoWrapper, s.Extra, s.ExtraLogo)}
							aria-hidden="true">
							<NextImage
								className={s.Logo}
								key={props.item.supplier.logo.url}
								src={props.item.supplier.logo.url}
								width={90}
								height={38}
							/>
							{!props.history && <span className={s.Contact}>{props.item.supplier.contact}</span>}
						</div>
					) : (
						<div className={s.Extra}>
							{props.item.supplier.name} {!props.history && props.item.supplier.contact}
						</div>
					)
				) : (
					<div className={s.Extra}>Nezáleží odkud</div>
				))}
			{history && props.item.supplier ? (
				<Link href={{ pathname: '/', query: { supplier: props.item.supplier.id } }} passHref>
					{props.item.supplier.logo ? (
						<a
							className={clsx(props.item.supplier.contact && s.LogoWrapper, s.Extra, s.ExtraLogo)}
							aria-hidden="true">
							<NextImage
								className={s.Logo}
								key={props.item.supplier.logo.url}
								src={props.item.supplier.logo.url}
								width={90}
								height={38}
							/>
							{!props.history && <span className={s.Contact}>{props.item.supplier.contact}</span>}
						</a>
					) : (
						<a className={s.Extra}>
							{props.item.supplier.name} {!props.history && props.item.supplier.contact}
						</a>
					)}
				</Link>
			) : (
				<div className={s.Extra}>Nezáleží odkud</div>
			)}
			<tr className={clsx(props.history && s.ItemWrapperReorder, s.ItemWrapper)}>
				<td className={s.ItemAttrs}>
					<div className={s.Title}>{props.item.title}</div>
				</td>
				<td className={s.ItemAttrs}>
					<div className={s.Amount}>{props.item.amount}</div>
				</td>

				<td className={clsx(isDue && !props.history && s.AlertItemDue, s.ItemDue)}>
					{props.item.dueDate && moment(props.item.dueDate).isValid() ? (
						<div>{checkDueDate(props.item.dueDate)}</div>
					) : (
						<div>&mdash;</div>
					)}
				</td>

				<td className={s.BtnGroup}>
					{props.history ? (
						<form
							className={s.Form}
							key={submitCount}
							onSubmit={async (e) => {
								e.preventDefault()
								const data = new FormData(e.currentTarget)
								try {
									const result = await api('createShoppingListItem', {
										title: props.item.title,
										amount: props.item.amount || '',
										dueDate: String(data.get('dueDate')),
										supplier: props.item.supplier?.id || '',
										shoppingList: '832809c6-6941-44cb-b92c-bc0c68fb1530',
									})
									if (result.ok === false) {
										alert(result.errorMessage)
									} else {
										router.replace('/')
										setSubmitCount((old) => old + 1)
									}
								} catch (e) {
									console.log(e)
								}
							}}>
							<SelectInput
								name="dueDate"
								defaultValue=""
								defaultLabel="Kdykoliv"
								history
								content={
									<>
										{dueDateOptions.map((date) => (
											<option value={date.value} key={date.label}>
												{date.label}
											</option>
										))}
									</>
								}
							/>
							<button type="submit" className={clsx(s.Btn, s.ReorderBtn)}>
								Reorder
							</button>
						</form>
					) : (
						<>
							<button type="button" className={clsx(s.Btn, s.ConfirmBtn)} onClick={handlePurchased}>
								<SvgCheckmark />
							</button>
							{doubleCheckVisible ? (
								<button
									type="button"
									className={clsx(s.Btn, s.DoubleCheckBtn)}
									onClick={handleRemoved}>
									Opravdu?
								</button>
							) : (
								<button type="button" className={clsx(s.Btn, s.RemoveBtn)} onClick={doubleCheck}>
									<SvgCross />
								</button>
							)}
						</>
					)}
				</td>
			</tr>
		</>
	)
}
