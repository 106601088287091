import clsx from 'clsx'
import { KeyboardEventHandler, useRef, useState } from 'react'
import { createFetcher } from '../../packages/@necktip/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import { dueDateOptions } from '../assets/helpers/dueDateOptions'
import { useRefresh } from '../assets/helpers/useRefresh'
import type { FavouriteItemResult } from '../data/FavouriteItemFragment'
import type { SupplierResult } from '../data/SupplierFragment'
import s from './AddItemForm.module.sass'
import { SelectInput } from './SelectInput'

const api = createFetcher<API>('/api/v1/[handler]')

export function AddItemForm(props: {
	suppliers: SupplierResult[]
	dataListItems: FavouriteItemResult[]
}) {
	const [submitCount, setSubmitCount] = useState(0)
	const [formIsVisible, setFormIsVisible] = useState(true)

	const favourites = props.dataListItems.map((item) => {
		return {
			title: `${item.title} ${item.supplier ? `(${item.supplier?.name})` : ''}`,
			item,
			key: item.title,
		}
	})

	const hideForm = () => {
		setFormIsVisible(false)
	}
	const openForm = () => {
		setFormIsVisible(true)
	}

	const refresh = useRefresh()

	const amountRef = useRef<HTMLInputElement | null>(null)
	const supplierRef = useRef<HTMLSelectElement | null>(null)

	const handleOptionClick: KeyboardEventHandler<HTMLInputElement> = (e) => {
		const value = e.currentTarget.value

		const favourite = favourites.find((fav) => fav.title === value)
		if (favourite) {
			if (amountRef.current && typeof favourite.item.amount === 'string') {
				amountRef.current.value = favourite.item.amount
			}
			if (supplierRef.current) {
				supplierRef.current.value = favourite.item.supplier?.id ?? ''
			}
			e.currentTarget.value = favourite.item.title
		}
	}

	return (
		<div className={s.FormWrapper}>
			{formIsVisible ? (
				<form
					className={s.Form}
					key={submitCount}
					onSubmit={async (e) => {
						e.preventDefault()
						const data = new FormData(e.currentTarget)
						try {
							const result = await api('createShoppingListItem', {
								amount: String(data.get('amount')),
								title: String(data.get('title')),
								dueDate: String(data.get('dueDate')),
								supplier: String(data.get('supplier')),
								shoppingList: '832809c6-6941-44cb-b92c-bc0c68fb1530',
							})
							if (result.ok === false) {
								alert(result.errorMessage)
							} else {
								refresh()
								setSubmitCount((old) => old + 1)
							}
						} catch (e) {
							console.log(e)
						}
					}}>
					<input
						type="text"
						name="title"
						placeholder="Co?"
						list="list-suggestions"
						onKeyUp={handleOptionClick}
					/>
					<datalist id="list-suggestions">
						{favourites?.map((item) => (
							<option className={s.Option} value={item.title} key={item.title} />
						))}
					</datalist>
					<input type="text" name="amount" ref={amountRef} placeholder="Kolik (ks, kg, apod.)" />
					<SelectInput
						name="dueDate"
						defaultValue=""
						defaultLabel="Kdykoliv"
						content={
							<>
								{dueDateOptions.map((date) => (
									<option value={date.value} key={date.label}>
										{date.label}
									</option>
								))}
							</>
						}
					/>
					<SelectInput
						name="supplier"
						defaultValue=""
						selectRef={supplierRef}
						defaultLabel="Dodavatel je mi fuk"
						content={
							<>
								{props.suppliers &&
									props.suppliers.map(
										(supplier) =>
											supplier && (
												<option value={supplier.id} key={supplier.id}>
													{supplier.name}
												</option>
											)
									)}
							</>
						}
					/>
					<div className={s.ButtonWrapper}>
						<button type="submit" className={s.Button}>
							<span>Přidat na seznam</span>
						</button>
					</div>
					<div className={clsx(s.HideButtonWrapper, s.HideDesktop)}>
						<button type="button" className={s.HideButton} onClick={hideForm}>
							<span className={s.Arrow} />
						</button>
					</div>
				</form>
			) : (
				<div className={s.AddButton}>
					<button type="button" className={s.HideButtonCollapsed} onClick={openForm}>
						<span className={s.HideButtonText}>Přidat něco</span>
						<span className={clsx(s.HideButtonWrapperCollapsed)}>
							<span className={clsx(s.Arrow, s.ArrowDown)} />
						</span>
					</button>
				</div>
			)}
		</div>
	)
}
