import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { createFetcher } from '../../packages/@necktip/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import { useRefresh } from '../assets/helpers/useRefresh'
import type { ShoppingListItemResult } from '../data/ShoppingItemFragment'
import s from './ShoppingListArticle.module.sass'
import { ShoppingListItemSupplier } from './ShoppingListItemSupplier'

const api = createFetcher<API>('/api/v1/[handler]')

export function ShoppingListArticleBySupplier(props: {
	items: ShoppingListItemResult[]
	history?: boolean
}) {
	const pendingItems = props.items.filter((item) => item.status === 'pending')
	const purchasedItems = props.items.filter((item) => item.status === 'purchased')

	const listedItems = props.history ? purchasedItems : pendingItems

	const groupedItemsBySupplier = Object.entries(_.groupBy(listedItems, 'supplier.name'))

	const refresh = useRefresh()

	return (
		<div>
			{groupedItemsBySupplier.map(([groupName, group]) => (
				<div
					key={groupName}
					className={clsx(
						s.Article,
						s.ArticleSupplier,
						group.length === 1 && s.ArticleSupplierMargin
					)}>
					{group.map((item) => (
						<ShoppingListItemSupplier item={item} key={item.id} history={props.history} />
					))}
					{group.length > 1 && !props.history && (
						<div className={s.ButtonWrapper}>
							<button
								className={s.Button}
								type="button"
								onClick={async () => {
									const ids = group.map((item) => item.id)
									await api('bulkUpdateShoppingListItem', {
										ids,
									})
									refresh()
								}}>
								Vše koupeno
							</button>
						</div>
					)}
				</div>
			))}
		</div>
	)
}
