import moment from 'moment'
import 'moment/locale/cs'

export const checkDueDate = (dueDate: string) => {
	const isYesterday = moment(dueDate).isSame(moment().subtract(1, 'days'), 'day')
	const isToday = moment(dueDate).isSame(moment(), 'day')
	const isTomorrow = moment(dueDate).isSame(moment().add(1, 'days'), 'day')

	if (isYesterday) {
		return 'včera'
	}
	if (isToday) {
		return 'dnes'
	}
	if (isTomorrow) {
		return 'zítra'
	}

	return moment(dueDate).format('L')
}

export const dueDateOptions = [
	{
		label: 'Zítra',
		value: moment().add(1, 'days').format(),
	},
	{
		label: `${moment().add(2, 'days').format('L')}`,
		value: moment().add(2, 'days').format(),
	},
	{
		label: `${moment().add(3, 'days').format('L')}`,
		value: moment().add(3, 'days').format(),
	},
	{
		label: `${moment().add(4, 'days').format('L')}`,
		value: moment().add(4, 'days').format(),
	},
	{
		label: `${moment().add(5, 'days').format('L')}`,
		value: moment().add(5, 'days').format(),
	},
	{
		label: `${moment().add(6, 'days').format('L')}`,
		value: moment().add(6, 'days').format(),
	},
	{
		label: `${moment().add(7, 'days').format('L')} (týden)`,
		value: moment().add(7, 'days').format(),
	},
	{
		label: `${moment().add(10, 'days').format('L')}`,
		value: moment().add(10, 'days').format(),
	},
	{
		label: `${moment().add(14, 'days').format('L')} (14 dní)`,
		value: moment().add(14, 'days').format(),
	},
]
