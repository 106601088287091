import clsx from 'clsx'
import React, { Ref } from 'react'
import s from './AddItemForm.module.sass'

export function SelectInput(props: {
	name: string
	defaultValue: string | undefined
	defaultLabel: string | undefined
	content: JSX.Element
	history?: boolean
	selectRef?: Ref<HTMLSelectElement>
}) {
	return (
		<div className={clsx(props.history && s.SelectWrapperReorder, s.SelectWrapper)}>
			<select name={props.name} className={s.Select} ref={props.selectRef}>
				<option value={props.defaultValue} defaultValue={props.defaultValue}>
					{props.defaultLabel}
				</option>
				{props.content}
			</select>
		</div>
	)
}
