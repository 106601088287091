import * as React from 'react'

function SvgCheckmark() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="1em" height="1em">
			<path d="M28.28 6.28L11 23.563l-7.28-7.28-1.44 1.437 8 8 .72.686.72-.687 18-18-1.44-1.44z" />
		</svg>
	)
}

export default SvgCheckmark
